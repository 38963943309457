import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./pages/comingSoon/comingSoon";
import AgeVerification from "./components/ageVerificationComponent";

function App() {
  return (
    <Router>
      <Routes>
        {/* Define the route for the ComingSoon component */}
        <Route path="/" element={<AgeVerification />} />
        {/* You can add more routes here for other pages */}
      </Routes>
    </Router>
  );
}

export default App;
