import React, { useState } from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import ComingSoon from "../pages/comingSoon/comingSoon";

const AgeVerification = () => {
  const [isVerified, setIsVerified] = useState(null); // Track age verification status

  // Function to handle age verification
  const handleAgeVerification = (isOldEnough) => {
    setIsVerified(isOldEnough);
  };

  // If the user is verified, show the ComingSoon component
  if (isVerified === true) {
    return <ComingSoon />;
  }

  // If the user is not old enough, show rejection message
  if (isVerified === false) {
    return (
      <Box
        sx={{
          backgroundColor: "#121212",
          color: "#f1f1f1",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Cormorant Upright",
            fontSize: "24px",
            color: "#DCCA87",
          }}
        >
          Sorry, you must be 18 or older to access this site.
        </Typography>
      </Box>
    );
  }

  // Render the age verification prompt
  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#f1f1f1",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Cormorant Upright",
          fontSize: "28px",
          color: "#DCCA87",
          mb: 2,
        }}
      >
        Are you 18 or older?
      </Typography>
      <Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#DCCA87",
            color: "#121212",
            fontFamily: "Open Sans",
            fontSize: "16px",
            mr: 2,
            "&:hover": { backgroundColor: "#b3946c" },
          }}
          onClick={() => handleAgeVerification(true)}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#DCCA87",
            color: "#121212",
            fontFamily: "Open Sans",
            fontSize: "16px",
            "&:hover": { backgroundColor: "#b3946c" },
          }}
          onClick={() => handleAgeVerification(false)}
        >
          No
        </Button>
      </Box>
    </Box>
  );
};

export default AgeVerification;
