import React from "react";
import { Box, Typography, Container } from "@mui/material";
import CountdownTimer from "../../components/countdownTimer";
import BrandLogos from "../../components/brandLogos";
import backgroundImage from "../../assets/images/comingSoonBg.png";
import companyLogo from "../../assets/images/BMLogo.png";
import spoonImage from "../../assets/images/Spoon.png";
import BarSection from "../../components/barSection";

const ComingSoon = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#f1f1f1",
        minHeight: "100vh",
        width: "100vw",
        position: "relative",
      }}
    >
      {/* Logo in top-left corner */}
      <Box
        component="img"
        src={companyLogo}
        alt="Company Logo"
        sx={{
          position: "absolute",
          top: { xs: 16, sm: 32 }, // Adjust for smaller screens
          left: { xs: 16, sm: 50 }, // Adjust for smaller screens
          width: { xs: "120px", sm: "200px", md: "264px" }, // Responsive width
          height: "auto",
        }}
      />

      {/* Container with maxWidth disabled to allow full width */}
      <Container maxWidth={false} sx={{ padding: 0 }}>
        <Box
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "#fff",
            height: { xs: "50vh", sm: "70vh" }, // Responsive height
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          {/* Coming Soon Text and Countdown Timer */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              padding: "20px 0",
              marginTop: { xs: "60px", sm: "120px" }, // Reduce margin for smaller screens
            }}
          >
            {/* Coming Soon Text */}
            <Typography
              sx={{
                fontFamily: "Cormorant Upright",
                fontSize: { xs: "32px", sm: "60px", md: "70px" }, // More responsive font size
                fontWeight: 500,
                lineHeight: { xs: "40px", sm: "80px", md: "100px" },
                letterSpacing: "0.04em",
                textAlign: "center",
                color: "#DCCA87",
              }}
            >
              Coming Soon
            </Typography>

            {/* Decorative Divider */}
            <Box
              component="img"
              src={spoonImage}
              alt="Decorative Spoon Icon"
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                width: { xs: "30px", sm: "40px" }, // Adjust width for smaller screens
                height: "auto",
              }}
            />

            {/* Description Text */}
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: { xs: "12px", sm: "16px" }, // Smaller font for smaller screens
                fontWeight: 400,
                lineHeight: "28px",
                letterSpacing: "0.02em",
                color: "#FFFFFF",
                maxWidth: "650px",
                textAlign: "center",
                paddingX: { xs: "20px", sm: "0px" }, // Add padding for smaller screens
              }}
            >
              BottleMaps revolutionizes the drinking experience with a virtual
              personalized bar system and cashless payment platform, allowing
              customers to enjoy their drinks at any partner venue.
            </Typography>
            <CountdownTimer />
          </Box>
        </Box>

        {/* Supporting Brands */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            padding: "20px 0",
          }}
        >
          <Box
            component="img"
            src={spoonImage}
            alt="Decorative Spoon Icon"
            sx={{
              marginTop: "10px",
              width: "40px",
            }}
          />

          <Typography
            sx={{
              fontFamily: "Cormorant Upright",
              fontSize: { xs: "30px", sm: "40px", md: "44px" }, // Responsive font size
              fontWeight: 500,
              letterSpacing: "0.04em",
              textAlign: "center",
              color: "#DCCA87",
            }}
          >
            Supporting Brands
          </Typography>
          <BrandLogos />
        </Box>
        <BarSection />
      </Container>
    </Box>
  );
};

export default ComingSoon;
