import React from "react";
import { Box, Typography, Container } from "@mui/material";

import logo1 from "../assets/images/baileysLogo.jpg";
import logo2 from "../assets/images/bombaySapphireLogo.jpg";
import logo3 from "../assets/images/glenfiddichLogo.jpg";
import logo4 from "../assets/images/gordonsLogo.jpg";
import logo5 from "../assets/images/JDLogo.jpg";
import logo6 from "../assets/images/JWLogo.png";
import logo7 from "../assets/images/SmirnoffLogo.png";

const BrandLogos = () => {
  return (
    <Container sx={{ paddingY: "40px", textAlign: "center" }}>
      {/* Flexbox to arrange the logos in a row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center the logos horizontally
          alignItems: "center", // Vertically align the logos
          flexWrap: "wrap", // Allows wrapping in case screen is smaller
          gap: "20px", // Space between logos
        }}
      >
        {/* Each image/logo in the row */}
        <Box
          component="img"
          src={logo1}
          alt="Logo 1"
          sx={{ width: "60px", height: "60px" }}
        />
        <Box
          component="img"
          src={logo2}
          alt="Logo 2"
          sx={{ width: "60px", height: "60px" }}
        />
        <Box
          component="img"
          src={logo3}
          alt="Logo 3"
          sx={{ width: "60px", height: "60px" }}
        />
        <Box
          component="img"
          src={logo4}
          alt="Logo 4"
          sx={{ width: "60px", height: "60px" }}
        />
        <Box
          component="img"
          src={logo5}
          alt="Logo 5"
          sx={{ width: "60px", height: "60px" }}
        />
        <Box
          component="img"
          src={logo6}
          alt="Logo 6"
          sx={{ width: "60px", height: "60px" }}
        />
        <Box
          component="img"
          src={logo7}
          alt="Logo 7"
          sx={{ width: "60px", height: "60px" }}
        />
      </Box>
    </Container>
  );
};

export default BrandLogos;
