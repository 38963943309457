import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import BarCardImage1 from "../assets/images/BarCardImage1.png"; // Replace with actual image paths
import BarCardImage2 from "../assets/images/BarCardImage2.png";
import BarCardImage3 from "../assets/images/BarCardImage3.png";
import FoodcoholicImage from "../assets/images/Foodcoholic-1.png";

const BarSection = () => {
  return (
    <Box sx={{ paddingY: "50px" }}>
      <Grid2
        container
        spacing={4}
        justifyContent="center" // Centers the item horizontally
      >
        {/* First Article */}
        <Grid2
          item
          xs={12}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              backgroundColor: "#121212",
              borderRadius: "8px",
              padding: "20px",
              width: "50%", // Ensures the box takes the full width of the grid item
            }}
          >
            {/* Article Image */}
            <Box
              component="img"
              src={FoodcoholicImage}
              alt="Grill"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                marginBottom: "10px",
              }}
            />
            {/* Date and Author */}
            {/* <Typography
              variant="body2"
              color="#FFFFFF"
              sx={{ marginBottom: "8px" }}
            >
              Foodcoholic
            </Typography> */}
            {/* Article Title */}
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Cormorant Upright",
                fontWeight: 600,
                fontSize: "24px",
                color: "#DCCA87",
                marginBottom: "10px",
              }}
            >
              Foodcoholic
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Cormorant Upright",
                fontWeight: 600,
                fontSize: "24px",
                color: "#DCCA87",
                marginBottom: "10px",
              }}
            >
              EAT . DRINK . SOCIALIZE
            </Typography>
            {/* Article Description */}
            {/* <Typography variant="body2" color="#FFFFFF">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat
              mattis ipsum turpis elit elit scelerisque egestas mus in.
            </Typography> */}
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default BarSection;
